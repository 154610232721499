import React, {useEffect, useState} from 'react';
import Loading from "../components/Loading";
import Grid from '@mui/material/Grid';
import {capitalizeFirstLetter, isSafari, MathMonthObject, retrieveCollegiaAccessToken} from "../utils/Helpers";
import {connect} from "react-redux";
import {returnMonthlyProjections} from "../actions/Profile";
import Wrapper from "../layout/Logged/Wrapper";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import calendarIcon from "../images/contributionHistoryCalendarIcon.svg";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {useTranslation, withTranslation} from "react-i18next";
import SideMenu from "../components/CollegiaAccount/SideMenu";
import {useNavigate} from "react-router-dom";

/**
 * @param monthly_projections
 * @param currentContribution
 * @param tabIndex
 * @param setTabIndex
 * @param setCurrentContribution
 * @param t
 * @param month
 */
const currentContributionInfo = (
	monthly_projections = [{
		total: 0,
		date: "",
	}],
	currentContribution = {},
	tabIndex,
	setTabIndex = () => {
	},
	setCurrentContribution = () => {
	},
	t = key => key,
	month = {},
) => {
	let monthData = new Date();

	monthly_projections?.forEach((d, index) => {
		const {
			total,
			date
		} = d;
		if (isSafari()) {
			monthData = new Date(date.replace(/-/g, "/"));
		} else {
			monthData = new Date(date);
		}

		month[monthData.getMonth()].total = (total + month[monthData.getMonth()].investment_return);
		month[monthData.getMonth()].year = monthData.getFullYear();

		if (month[monthData.getMonth()].monthNumber === new Date().getMonth()) {
			month[monthData.getMonth()].monthName = t("individual.today");
			setCurrentContribution({
				active: d?.active,
				created_at: d?.created_at,
				date: d?.date,
				employer_contributions: d?.employer_contributions,
				formatted_total: d?.formatted_total,
				id: d?.id,
				individual_id: d?.individual_id,
				investment_return: d?.investment_return,
				monthIndex: d?.monthIndex,
				monthName: d?.monthName,
				pension_pots_being_transferred: d?.pension_pots_being_transferred,
				pension_pots_transferred_to_collegia: d?.pension_pots_transferred_to_collegia,
				startIndex: d?.startIndex,
				total: (parseFloat(d?.total) + (d?.investment_return !== null ? parseFloat(d?.investment_return) : 0.00)),
				total_fee: d?.total_fee,
				total_pot: d?.total_pot,
				transaction_fee_id: d?.transaction_fee_id,
				transaction_id: d?.transaction_id,
				uk_gov_top_up: d?.uk_gov_top_up,
				updated_at: d?.updated_at,
				your_contributions: d?.your_contributions,
				monthInfo: {
					investment_return: month[monthData.getMonth()].investment_return,
					monthFullName: month[monthData.getMonth()].monthFullName,
					monthName: month[monthData.getMonth()].monthName,
					monthNumber: month[monthData.getMonth()].monthNumber,
					total: (month[monthData.getMonth()].total + month[monthData.getMonth()].investment_return),
					year: monthData.getFullYear()
				},
			});
		}
	});
};

/**
 * @param index
 * @returns {{"aria-controls": string, id: string}}
 */
function a11yProps(index: number = 0) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

/**
 * @param monthly_projections
 * @param t
 * @returns {[]}
 * @private
 */
const _mountMonthTabs = (monthly_projections: [], t = key => key): [] => {
	const months = [];

	for (let i = 0; i < 3; i++) {
		let month = 1;

		if (monthly_projections[i]?.monthIndex) {
			month = monthly_projections[i]?.monthIndex - 1;
		}

		if (month < 0) {
			month = i;
		}

		months.push(
			<MenuItem
				key={i}
				className={"tab-button"}
				{...a11yProps(month)}
				value={i}
			>
				{MathMonthObject(t)[month].monthFullName}
			</MenuItem>
		);
	}

	return months;
};

/**
 * @param tabIndex
 * @param profile_data
 * @param currentContribution
 * @param setCurrentContribution
 * @param t
 * @param month
 */
const changeMonthContribution = (
	tabIndex: number = 2,
	profile_data: any = [],
	currentContribution: {},
	setCurrentContribution = () => {
	},
	t = key => key,
	month = MathMonthObject(t),
) => {
	if (profile_data?.monthly_projections[tabIndex]) {
		const {
			date,
			total = 0,
			investment_return = 0,
		} = profile_data?.monthly_projections[tabIndex];
		let monthData;

		if (isSafari()) {
			monthData = new Date(date.replace(/-/g, "/"));
		} else {
			monthData = new Date(date);
		}

		month[monthData.getMonth()].total = total + investment_return;
		month[monthData.getMonth()].investment_return = investment_return;
		month[monthData.getMonth()].year = monthData.getFullYear();
		if (currentContribution !== undefined) {
			setCurrentContribution({
				active: profile_data?.monthly_projections[tabIndex]?.active,
				created_at: profile_data?.monthly_projections[tabIndex]?.created_at,
				date: profile_data?.monthly_projections[tabIndex]?.date,
				employer_contributions: profile_data?.monthly_projections[tabIndex]?.employer_contributions,
				formatted_total: profile_data?.monthly_projections[tabIndex]?.formatted_total,
				id: profile_data?.monthly_projections[tabIndex]?.id,
				individual_id: profile_data?.monthly_projections[tabIndex]?.individual_id,
				investment_return: profile_data?.monthly_projections[tabIndex]?.investment_return,
				monthIndex: profile_data?.monthly_projections[tabIndex]?.monthIndex,
				monthName: profile_data?.monthly_projections[tabIndex]?.monthName,
				startIndex: profile_data?.monthly_projections[tabIndex]?.startIndex,
				total: (
					parseFloat(profile_data?.monthly_projections[tabIndex]?.total) +
					(profile_data?.monthly_projections[tabIndex]?.investment_return !== null
						? parseFloat(profile_data?.monthly_projections[tabIndex]?.investment_return) : 0.00)
				),
				total_fee: profile_data?.monthly_projections[tabIndex]?.total_fee,
				total_pot: profile_data?.monthly_projections[tabIndex]?.total_pot,
				transaction_fee_id: profile_data?.monthly_projections[tabIndex]?.transaction_fee_id,
				transaction_id: profile_data?.monthly_projections[tabIndex]?.transaction_id,
				uk_gov_top_up: profile_data?.monthly_projections[tabIndex]?.uk_gov_top_up,
				updated_at: profile_data?.monthly_projections[tabIndex]?.updated_at,
				your_contributions: profile_data?.monthly_projections[tabIndex]?.your_contributions,
				pension_pots_being_transferred: profile_data?.monthly_projections[tabIndex]?.pension_pots_being_transferred,
				pension_pots_transferred_to_collegia: profile_data?.monthly_projections[tabIndex]?.pension_pots_transferred_to_collegia,
				monthInfo: {
					investment_return: month[monthData.getMonth()].investment_return,
					monthFullName: month[monthData.getMonth()].monthFullName,
					monthName: month[monthData.getMonth()].monthName,
					monthNumber: month[monthData.getMonth()].monthNumber,
					total: month[monthData.getMonth()].total + month[monthData.getMonth()].investment_return,
					year: monthData.getFullYear(),
				},
			});
		}
	}
};

/**
 * @param tabIndex
 * @param profile_data
 * @param setTabIndex
 * @param currentContribution
 * @param setCurrentContribution
 * @param t
 * @param month
 */
const changeMonthTabIndex = (
	tabIndex: number = 2,
	profile_data: any = [],
	setTabIndex = () => {
	},
	currentContribution: {},
	setCurrentContribution = () => {
	},
	t = key => key,
	month = MathMonthObject(t),
) => {
	setTabIndex(tabIndex);
	changeMonthContribution(
		tabIndex,
		profile_data,
		currentContribution,
		setCurrentContribution,
		t,
		month,
	);
};

/**
 * @param contribution
 * @returns {string|number|number}
 */
const renderEmployerContributionsAmount = (contribution) => {
	if (isNaN(contribution?.employer_contributions)) {
		return 0;
	}

	return parseFloat(
		contribution
			?.employer_contributions
			?.replace(",", "")
		?? 0
	)
		.toLocaleString('en-GB', {
			style: 'currency',
			currency: 'GBP',
		}) ?? 0;
}

/**
 * @param profile_data
 * @param profile_loading
 * @param returnMonthlyProjections
 * @param monthly_projections_loading
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const Contributions = (
	{
		profile_data = {
			individual_investment_approach: {
				investment_approach: {
					yearly_charge: 0
				}
			},
			portfolio: {
				investment_return: 0
			},
			total_collegia_account_to_be_collected: 0,
			generic_projection_parameter: {
				generic_projection_parameter: {
					standard_contribution_charge: 0
				}
			},
			monthly_projections: []
		},
		monthly_projections_loading = false,
		returnMonthlyProjections = () => {},
	},
): JSX.Element => {
	const [tabIndex, setTabIndex] = useState(0),
		[currentContribution, setCurrentContribution] = useState({
			created_at: "",
			date: "",
			formatted_total: "",
			monthIndex: 1,
			monthInfo: {
				monthFullName: "",
				monthName: "",
				monthNumber: 0,
				total: "",
				year: 0,
			},
			total: "0.00",
			employer_contributions: "0.00",
			investment_return: "0.00",
			uk_gov_top_up: "0.00",
			your_contributions: "0.00",
			updated_at: "",
		}),
		{t} = useTranslation(),
		history = useNavigate();

	useEffect(() => {
		returnMonthlyProjections({
			profiles: {
				isStatePension: false,
				isExpectedRetirementAge: false,
				isContributionsStatistics: true
			}
		});
	}, [returnMonthlyProjections]);

	useEffect(() => {
		if (profile_data?.monthly_projections && currentContribution?.created_at?.length === 0) {
			currentContributionInfo(
				profile_data?.monthly_projections,
				currentContribution,
				tabIndex,
				setTabIndex,
				setCurrentContribution,
				t,
				MathMonthObject(t),
			);
		}
	}, [
		profile_data?.monthly_projections,
		currentContribution,
		tabIndex,
		t,
	]);

	if (!profile_data?.id || monthly_projections_loading) {
		return (
			<Loading absolute/>
		);
	}

	if (!retrieveCollegiaAccessToken()) {
		window.location = "/login";
	}

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
			hideFooter={true}
			t={t}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				item
				direction={"row"}
				container
				className={"grid"}
			>
				<SideMenu
					history={history}
					t={t}
					contributions={""}
				/>
				<Grid
					xl={9.5}
					lg={9.5}
					md={12}
					sm={12}
					xs={12}
					item
					direction={"row"}
					container
					justifyContent={"center"}
					id={"contributions-container"}
				>
					<Grid
						xl={10.5}
						lg={10.5}
						md={11}
						sm={11}
						xs={11}
						item
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							className={"first-grid"}
							justifyContent={"space-between"}
						>
							<Typography
								className={"container-title"}
							>
								{t("individual.collegia_account.inner_menu.contributions")}
							</Typography>
							<Grid
								xs={6}
								sm={6}
								md={6}
								lg={6}
								xl={6}
								item
								className={"select-date-background-color"}
								textAlign={"end"}
							>
								<TextField
									select
									value={tabIndex}
									className={"text-field-selector"}
									onChange={
										(e) => {
											setTabIndex(e.target.value);
											setCurrentContribution(profile_data?.monthly_projections[tabIndex]);
											changeMonthTabIndex(
												e.target.value,
												profile_data,
												setTabIndex,
												currentContribution,
												setCurrentContribution
											);
										}}
									InputProps={{
										startAdornment: (
											<InputAdornment position={"start"}>
												<img
													className={"background-img"}
													src={calendarIcon}
													alt={""}
												/>
											</InputAdornment>
										)
									}}
								>
									{_mountMonthTabs(profile_data?.monthly_projections ?? [], t)}
								</TextField>
							</Grid>
						</Grid>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							className={"second-grid"}
							textAlign={"center"}
						>
							<Typography
								className={"title-first-row"}
							>
								{capitalizeFirstLetter(t("individual.collegia_contribution.total_pot").toLowerCase())}
							</Typography>
							<Typography
								className={"total-pot"}
							>
								{
									currentContribution?.total ? currentContribution?.total.toLocaleString('en-GB', {
										style: 'currency',
										currency: 'GBP',
									}) : "0.00"
								}
							</Typography>
						</Grid>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
						>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								className={"grid-contributions third-grid mobile-right"}
								borderRight={"5px solid #F2F2F4"}
							>
								<Typography
									className={"title-second-row"}
								>
									{t("individual.your_contributions")}
								</Typography>
								<Typography
									className={"values-second-row"}
								>
									{parseFloat(
										currentContribution
											?.your_contributions
											?.replace(",", "")
										?? 0
									).toLocaleString('en-GB', {
										style: 'currency',
										currency: 'GBP',
									}) ?? 0}
								</Typography>
							</Grid>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								className={"grid-contributions third-grid mobile-left"}
								borderRight={"5px solid #F2F2F4"}
								borderLeft={"5px solid #F2F2F4"}
							>
								<Typography
									className={"title-second-row"}
								>
									{t("individual.collegia_account.employers_contributions")}
								</Typography>
								<Typography
									className={"values-second-row"}
								>
									{renderEmployerContributionsAmount(currentContribution)}
								</Typography>
							</Grid>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								borderRight={"5px solid #F2F2F4"}
								borderLeft={"5px solid #F2F2F4"}
								className={"grid-contributions third-grid mobile-right"}
							>
								<Typography
									className={"title-second-row"}
								>
									{t("individual.collegia_account.gov_top_up")}
								</Typography>
								<Typography
									className={"values-second-row"}
								>
									{parseFloat(
										currentContribution
											?.uk_gov_top_up
											?.replace(",", "")
										?? 0
									)
										.toLocaleString('en-GB', {
											style: 'currency',
											currency: 'GBP',
										}) ?? 0}
								</Typography>
							</Grid>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								borderLeft={"5px solid #F2F2F4"}
								className={"grid-contributions third-grid mobile-left"}
							>
								<Typography
									className={"title-second-row"}
								>
									{t("individual.collegia_account.investment_return")}
								</Typography>
								<Typography
									className={
										profile_data?.portfolio?.investment_return >= 0 ?
											"values-second-row"
											:
											"values-second-row-negative"
									}
								>
									{parseFloat(
										currentContribution?.investment_return
										?? 0
									)
										.toLocaleString('en-GB', {
											style: 'currency',
											currency: 'GBP',
										}) ?? 0}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
						>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								className={"grid-contributions fourth-grid mobile-right"}
								borderRight={"5px solid #F2F2F4"}
							>
								<Typography
									className={"title-second-row"}
								>
									{t("individual.collegia_contribution.pensions_pots_transferred_to_collegia")}
								</Typography>
								<Typography
									className={"values-third-row"}
								>
									{parseFloat(
										currentContribution
											?.pension_pots_transferred_to_collegia
											?.replace(",", "")
										?? 0
									)
										.toLocaleString('en-GB', {
											style: 'currency',
											currency: 'GBP',
										}) ?? 0}
								</Typography>
							</Grid>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								borderRight={"5px solid #F2F2F4"}
								borderLeft={"5px solid #F2F2F4"}
								className={"grid-contributions fourth-grid mobile-left"}
							>
								<Typography
									className={"title-second-row"}
								>
									{t("individual.collegia_contribution.pensions_pots_being_transferred")}
								</Typography>
								<Typography
									className={"values-third-row"}
								>
									{parseFloat(
										currentContribution
											?.pension_pots_being_transferred
											?.replace(",", "")
										?? 0
									)
										.toLocaleString('en-GB', {
											style: 'currency',
											currency: 'GBP',
										}) ?? 0}
								</Typography>
							</Grid>
							<Grid
								xl={6}
								lg={6}
								md={6}
								sm={12}
								xs={12}
								item
								textAlign={"center"}
								borderLeft={"5px solid #F2F2F4"}
								className={"grid-contributions fifth-grid"}
							>
								<Typography
									className={"title-second-row"}
								>
									{t("individual.collegia_account.contributions_being_processed.title")}
								</Typography>
								<Typography
									className={"values-third-row"}
								>
									{parseFloat(
											profile_data
												?.total_collegia_account_to_be_collected
												?.replace(",", "")
											?? 0
										).toLocaleString('en-GB', {
											style: 'currency',
											currency: 'GBP',
										})
										?? 0
									}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
						>
							<Grid
								xl={12}
								lg={12}
								md={12}
								sm={12}
								xs={12}
								container
								item
								justifyContent={"space-around"}
							>
								<Typography
									className={"fee"}
								>
									{t("individual.collegia_account.ongoing_fees.title")}
								</Typography>
								<Typography
									className={"fee"}
								>
									{t("individual.collegia_account.one_off_fees.title")}
								</Typography>
							</Grid>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								className={"grid-contributions fifth-grid"}
							>
								<Typography
									className={"title-second-row"}
									borderRight={"0.5px solid #707070"}
								>
									{t("individual.collegia_account.ongoing_fees.collegia_annual_charge")}
								</Typography>
								<Typography
									className={"values-third-row"}
									borderRight={"0.5px solid #707070"}
								>
									{
										profile_data?.individual_investment_approach?.investment_approach?.fund?.projection_fee?.collegia_fee_percentage
											? profile_data?.individual_investment_approach?.investment_approach?.fund?.projection_fee?.collegia_fee_percentage
											: "0.11"
									} %
								</Typography>
							</Grid>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								borderRight={"5px solid #F2F4F6"}
								className={"grid-contributions fifth-grid"}
							>
								<Typography
									borderLeft={"0.5px solid #707070"}
									className={"title-second-row"}
								>
									{t("individual.collegia_account.ongoing_fees.fund_annual_cost")}
								</Typography>
								<Typography
									borderLeft={"0.5px solid #707070"}
									className={"values-third-row"}
								>
									{
										profile_data?.individual_investment_approach?.investment_approach?.fund?.projection_fee?.provider_fee_percentage
											? profile_data?.individual_investment_approach?.investment_approach?.fund?.projection_fee?.provider_fee_percentage
											: "0.29"
									} %
								</Typography>
							</Grid>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								borderLeft={"5px solid #F2F4F6"}
								className={"grid-contributions fifth-grid"}
							>
								<Typography
									className={"title-second-row"}
									borderRight={"0.5px solid #707070"}
								>
									{t("individual.collegia_account.one_off_fees.contribution_charge_percent")}
								</Typography>
								<Typography
									className={"values-third-row"}
									borderRight={"0.5px solid #707070"}
								>
									{
										profile_data
											?.generic_projection_parameter
											?.generic_projection_parameter
											?.standard_contribution_charge
											?.toFixed(2)
											.toLocaleString({
												style: 'currency',
												currency: 'GBP',
											})
										?? 1.70
									} %
								</Typography>
							</Grid>
							<Grid
								xl={3}
								lg={3}
								md={3}
								sm={6}
								xs={6}
								item
								textAlign={"center"}
								className={"grid-contributions fifth-grid"}
							>
								<Typography
									borderLeft={"0.5px solid #707070"}
									className={"title-second-row"}
								>
									{t("individual.collegia_account.one_off_fees.contribution_charge_value")}
								</Typography>
								<Typography
									borderLeft={"0.5px solid #707070"}
									className={"values-third-row"}
								>
									{
										parseFloat(
											currentContribution
												?.total_fee
												?.replace(",", "")
											?? 0
										).toLocaleString('en-GB', {
											style: 'currency',
											currency: 'GBP',
										})
										?? 0
									}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Wrapper>
	);
};

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = (state = [{
	individual_investment_approach: {
		investment_approach: {}
	}
}]) => state.Profile;

/**
 * @param dispatch
 * @returns {{me: (function(): *)}}
 */
const mapDispatchToProps = dispatch => ({
	returnMonthlyProjections: (data = false) => dispatch(returnMonthlyProjections(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Contributions));